<template>
  <div class="page-container">
    <!--面包屑导航区-->
    <!--    <el-breadcrumb separator-class="el-icon-arrow-right">-->
    <!--      <el-breadcrumb-item :to="{ path: '/welcome' }">主页</el-breadcrumb-item>-->
    <!--      <el-breadcrumb-item>工单管理</el-breadcrumb-item>-->
    <!--    </el-breadcrumb>-->
    <Breadcrumb></Breadcrumb>
    <!--操作区-->
    <el-card>
      <el-form :model="form" :inline="true" size="small">
        <el-form-item label="患者信息">
          <el-input
            class="inputWidth"
            v-model="form.userInfo"
            placeholder="请输入患者姓名 / 手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="医院名称">
          <el-select
            v-model="form.hospitalId"
            clearable
            filterable
            placeholder="请选择医院"
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in hospitalList"
              :key="index"
              :label="item.hospitalName"
              :value="item.hospitalId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联医生" prop="assistantId">
          <el-select
            v-model="form.assistantId"
            placeholder="选择关联医生"
            filterable
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in assistantList"
              :key="index"
              :label="item.assistantName"
              :value="item.assistantId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="医生工作室">
          <el-select
            v-model="form.doctorGroup"
            clearable
            filterable
            placeholder="请选择工作室"
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in doctorOfficeList"
              :key="index"
              :label="item.groupName"
              :value="item.groupId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="患者类型">
          <el-select v-model="form.patientStatus" placeholder="请选择">
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="item in patientList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="随访时间">
          <DatePicker
            :start-time.sync="form.startTime"
            :end-time.sync="form.endTime"
          />
        </el-form-item>
        <el-form-item label="工单状态" :key="0" v-show="type === '1'">
          <el-select
            v-model="form.status"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.key"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="primary" plain @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="form-region">
      <el-tabs v-model="type" @tab-click="tabClick" type="card">
        <el-tab-pane label="待跟进" name="0"></el-tab-pane>
        <el-tab-pane label="已处理" name="1"></el-tab-pane>
      </el-tabs>
      <!-- 需求有问题，该页面暂时不要新增功能呢 -->
      <!-- <nav>
        <el-button type="primary" @click="addEdit()">新增</el-button>
      </nav>-->
      <el-table :data="tableData.items" border class="table" size="mini">
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="55"
        ></el-table-column>
        <el-table-column align="center" label="问题描述">
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.problemDescription &&
                scope.row.problemDescription.length <= 10
              "
            >
              {{ scope.row.problemDescription }}
            </div>
            <div v-else>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <template slot="content">
                  <div class="tooltop">{{ scope.row.problemDescription }}</div>
                </template>
                <span
                  >{{
                    scope.row.problemDescription
                      ? scope.row.problemDescription.slice(0, 10)
                      : ""
                  }}...</span
                >
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="患者姓名"
          prop="name"
        ></el-table-column>
        <el-table-column align="center" label="患者类型" prop="currentState">
          <template slot-scope="scope">
            <span>{{
              scope.row.currentState === 1 ? "精准管理" : "自主管理"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="医院名称"
          prop="hospitalName"
        ></el-table-column>
        <el-table-column
          align="center"
          label="医生工作室"
          prop="groupName"
        ></el-table-column>
        <el-table-column
          align="center"
          label="关联医生"
          prop="assistantName"
        ></el-table-column>
        <el-table-column align="center" label="本次随访次数">
          <template slot-scope="scope">
            <div v-show="scope.row.times > 0">
              第{{ scope.row.times }}次随访
            </div>
            <div v-show="scope.row.times === 0">
              {{ scope.row.workOrderType === 0 ? "个性化随访" : "个性化复查" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime">
          <template slot-scope="socpe">{{
            socpe.row.createTime | dateFormat("YYYY-MM-DD HH:mm")
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          label="工单状态"
          :key="0"
          v-if="type === '1'"
        >
          <template slot-scope="scope">{{
            type === "0"
              ? "待确定"
              : scope.row.status === 0
              ? "已退回"
              : "接受并处理"
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          label="医生结论"
          :key="1"
          v-if="type === '1'"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.assistantConclusion">
              <div
                v-if="
                  scope.row.assistantConclusion &&
                  scope.row.assistantConclusion.length <= 10
                "
              >
                {{ scope.row.assistantConclusion }}
              </div>
              <div v-else>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <template slot="content">
                    <div class="tooltop">
                      {{ scope.row.assistantConclusion }}
                    </div>
                  </template>
                  <span
                    >{{
                      scope.row.assistantConclusion
                        ? scope.row.assistantConclusion.slice(0, 10)
                        : ""
                    }}...</span
                  >
                </el-tooltip>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作列">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="showDetail(scope.row)"
              :loading="scope.row.detailLoading"
              size="mini"
              >查看详情</el-button
            >
            <!-- <el-button v-show="scope.row.status === 1" type="text" @click="addEdit(scope.row)">编辑</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :page.sync="form.page"
        :size.sync="form.pageSize"
        :total="tableData.totalCount"
        @change="getListByType"
      />

      <AddEdit v-if="addEditForm.visible" :data.sync="addEditForm" />
    </div>
  </div>
</template>

<script>
import AddEdit from "@/views/ordermanage/components/AddEdit";
import DatePicker from "@/views/ordermanage/components/DatePicker";
import Pagination from "@/views/ordermanage/components/Pagination";
import { selectparams } from "../../mixins/selectparams";
export default {
  name: "OrderManage",
  components: { AddEdit, DatePicker, Pagination },
  mixins: [selectparams],
  data() {
    return {
      form: {
        userInfo: "",
        doctorGroup: "",
        startTime: "",
        endTime: "",
        patientStatus: "",
        hospitalId: "",
        assistantId: "",
        page: 1,
        pageSize: 10,
        status: "",
        customerServerId: JSON.parse(sessionStorage.getItem("userInfo"))
          .customerServerId,
      },
      tableData: {
        totalCount: 0,
        items: [],
      },
      doctorOfficeList: [],
      assistantList: [],
      hospitalList: [],
      addEditForm: {},
      type: "0",
      unStatusList: [{ key: "全部", value: "" }],
      statusList: [
        { key: "拒绝", value: 0 },
        { key: "接受并处理", value: 1 },
      ],
      workRoomList: [],
      patientList: [
        {
          name: "精准管理",
          value: 1,
        },
        {
          name: "自主管理",
          value: 0,
        },
      ],
    };
  },
  created() {
    this.init();
    this.getUntreatedListDefault();
    this.getAssistantName();
    console.log(this.form);
  },
  methods: {
    init() {
      this.$http.getAssistantGroups(null).then((res) => {
        const { result } = res;
        this.workRoomList = result.data;
      });
    },

    // 未处理查询
    getUntreatedListDefault() {
      this.form.page = 1;
      this.form.pageSize = 10;
      this.getUntreatedList();
    },

    getUntreatedList() {
      this.$http.findWorkList(this.form).then((res) => {
        const { result } = res;
        this.tableData.items = result.data.data;
        this.tableData.totalCount = result.data.records;
      });
    },

    // 已处理查询
    getTreatedListDefault() {
      this.form.page = 1;
      this.form.pageSize = 10;
      this.getTreatedList();
    },

    getTreatedList() {
      this.$http.findProcessedList(this.form).then((res) => {
        const { result } = res;
        this.tableData.items = result.data.data;
        this.tableData.totalCount = result.data.records;
      });
    },
    getAssistantName() {
      this.$http
        .getAssistantName({
          customerServerId: JSON.parse(sessionStorage.getItem("userInfo"))
            .customerServerId,
        })
        .then((res) => {
          console.log(res, "========获取关联医助=======");
          this.assistantList = res.result.data;
        });
    },

    search() {
      if (this.type === "0") {
        this.getUntreatedListDefault();
      } else {
        this.getTreatedListDefault();
      }
    },

    tabClick() {
      this.form.status = "";
      this.search();
    },

    getListByType() {
      // 待处理查询
      if (this.type === "0") {
        this.getUntreatedList();
      } else {
        this.getTreatedList();
      }
    },

    resetSearch() {
      this.form = {
        userInfo: "",
        doctorGroup: "",
        startTime: "",
        endTime: "",
        patientStatus: "",
        hospitalId: "",
        assistantId: "",
        page: 1,
        pageSize: 10,
        status: "",
        customerServerId: JSON.parse(sessionStorage.getItem("userInfo"))
          .customerServerId,
      };
      this.search();
    },

    async searchDetail(id, status) {
      try {
        let data = {};
        // 已回退工单查询
        const { result } = await this.$http.getWorkManageDetails({ id });
        data = result.data;
        return Promise.resolve(data);
      } catch (err) {
        this.$message.error("查询失败，请联系管理员！");
        return Promise.reject({});
      }
    },

    showDetail(row) {
      this.addEditForm = {
        isAdd: !!row.id,
        isLook: true,
        title: "查看详情",
        form: {},
        getListDefault: this.getListDefault,
        visible: false,
      };
      this.$set(row, "detailLoading", true);
      this.searchDetail(row.id, row.status)
        .then((res) => {
          console.log(res, "==========================");
          this.addEditForm.form = res;
          this.addEditForm.visible = true;
          row.detailLoading = false;
        })
        .catch(() => {
          row.detailLoading = false;
        });
    },

    async addEdit(row) {
      if (this.workRoomList.length === 0) {
        return this.$message.warning("暂无医生工作室，或请稍后再试！");
      }
      this.addEditForm = {
        isAdd: !row,
        isLook: false,
        title: !row ? "编辑工单" : "新建工单",
        form: {},
        getListDefault: this.getListDefault,
        visible: false,
      };
      if (row) {
        this.addEditForm.form = row;
        this.addEditForm.visible = true;
      }
    },
  },
};
</script>

<style scoped>
.content {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  overflow: hidden;
  overflow-y: auto;
}
.inputWidth {
  width: 217px;
}
.table {
  margin-bottom: 10px;
}
.tooltop {
  display: inline-block;
  width: 350px;
}
.form-region {
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 10px;
  flex: 1;
  box-sizing: border-box;
  padding: 16px 20px;
}
</style>
