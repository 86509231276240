<template>
  <el-date-picker
    v-model="value"
    :type="type"
    :picker-options="pickerOptions"
    :range-separator="rangeSeparator"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    :align="align"
    :value-format="valueFormat"
    :default-time="defaultTime"
  ></el-date-picker>
</template>
<script>
export default {
  name: "DatePicker",
  props: {
    type: {
      type: String,
      default: "datetimerange",
    },
    startTime: {
      type: String,
      default: "",
      require: true,
    },
    endTime: {
      type: String,
      default: "",
      require: true,
    },
    startPlaceholder: {
      type: String,
      default: "开始日期",
    },
    endPlaceholder: {
      type: String,
      default: "结束日期",
    },
    rangeSeparator: {
      type: String,
      default: "至",
    },
    align: {
      type: String,
      default: "right",
    },
    valueFormat: {
      type: String,
      default: "yyyy-MM-dd HH:mm:ss",
    },
    defaultTime: {
      type: Array,
      default: () => {
        return ["00:00:00", "23:59:59"];
      },
    },
    // 自己定义快捷选项具体内容
    userOptions: {
      type: Object,
      default: () => { return {} }
    },
    disabledDate: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      pickerOptions: {
        // shortcuts: [
        // {
        //   text: "最近一周",
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        //     picker.$emit("pick", [start, end]);
        //   },
        // },
        // {
        //   text: "最近一个月",
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        //     picker.$emit("pick", [start, end]);
        //   },
        // },
        // {
        //   text: "最近三个月",
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        //     picker.$emit("pick", [start, end]);
        //   },
        // },
        // ],
      },
    };
  },
  computed: {
    value: {
      get() {
        return [this.startTime, this.endTime];
      },
      set(val) {
        //   三目运算符是为了解决清空按钮带来的报错问题
        this.$emit("update:startTime", val ? val[0] : "");
        this.$emit("update:endTime", val ? val[1] : "");
      },
    },
  },
  created() {
    if (this.userOptions.shortcuts && this.userOptions.shortcuts.length > 0) {
      this.pickerOptions.shortcuts = this.userOptions.shortcuts
    }

    if (this.disabledDate) {
      this.pickerOptions.disabledDate = this.disabledDate
    }
  }
};
</script>
<style scoped>
</style>


