<template>
  <el-pagination
    @size-change="changeSize"
    @current-change="changeCurrent"
    :current-page="page"
    :page-sizes="pageSizes"
    :page-size="size"
    layout="slot, sizes, prev, pager, next, jumper"
    :total="total"
  >
    <span class="total">共 {{ total }} 条数据，共 {{ Math.ceil(total / size) }} 页</span>
  </el-pagination>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    page: {
      type: Number,
      default: 1,
      require: true,
    },
    size: {
      type: Number,
      default: 25,
      require: true,
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 20, 50];
      },
    },
    total: {
      type: Number,
      default: 0,
      require: true,
    }
  },
  methods: {
    changeSize(val) {
      this.$emit("update:size", val);
      this.$emit("change");
    },
    changeCurrent(val) {
      this.$emit("update:page", val);
      this.$emit("change");
    },
  },
};
</script>

<style scoped>
.total {
  margin-right: 24px;
  font-weight: 400 !important;
  color: #606266;
}
</style>