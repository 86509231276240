<template>
  <el-dialog
    :title="data.title"
    :visible.sync="data.visible"
    width="700px"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <el-form
      :model="data.form"
      ref="form"
      :rules="rules"
      label-width="90px"
      :disabled="data.isLook"
    >
      <el-form-item label="医生工作室">
        <el-input v-model="data.form.groupName" class="inputWidth" :disabled="!data.isAdd"></el-input>
      </el-form-item>
      <el-form-item label="患者姓名">
        <el-input v-model="data.form.name" class="inputWidth" :disabled="!data.isAdd"></el-input>
      </el-form-item>
      <el-form-item label="事件描述">
        <el-input
          v-model="data.form.problemDescription"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="医助结论" v-if="data.isLook">
        <el-input
          v-model="data.form.assistantConclusion"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10 }"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose">关 闭</el-button>
      <!-- <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ordermanage",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      rules: {},
      loading: false
    };
  },
  mounted() {
    console.log(this.data,'=========================================')
  },
  methods: {
    handleClose() {
      this.data.visible = false;
    },

    submitForm() {
      this.$refs.form.validate((res) => {
        if (!res) {
          return;
        }
        this.loading = true
        this.$http.updateWorkManage({ value: this.data.form }).then(() => {
          this.$message.success('编辑成功')
          this.data.getListDefault()
          this.data.visible = false
        }).catch(() => {
          this.loading = false
          this.$message.error('保存失败，请稍后再试！')
        })
      });
    },
  },
};
</script>

<style scoped>
.inputWidth {
  width: 217px;
}
</style>